import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import Seo from '@src/components/Seo'
import { BrickResolver } from '@src/utils/BrickResolver'
import { SITE_TYPES } from '@src/bundles/site'

const StartPage = ({ pageContext, location }) => {
	const { siteType } = useConnect('selectSiteType')

	useEffect(() => {
		if (location !== undefined) {
			if (siteType === SITE_TYPES.BUSINESS) {
				navigate(`/foretag${location.search}`, { replace: true })
			} else if (siteType === SITE_TYPES.PRIVATE) {
				navigate(`/${location.search}`, { replace: true })
			}
		}
	}, [siteType]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Seo pageContextData={pageContext} />
			<BrickResolver pageContext={pageContext}></BrickResolver>
		</>
	)
}

export default StartPage
